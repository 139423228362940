import React from "react"
import { Link, graphql } from "gatsby"
import parse from "html-react-parser"
import styled from "styled-components"
import Zoom from "react-medium-image-zoom"
import {
  action_link,
  action_link_reverse,
  inner,
  featured_image,
  image_aspect,
} from "@css/global"
import { icg_blue } from "@css/colours"
import { Scroller, Layout, Seo } from "@components"
import { Fade } from "react-reveal"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import "react-medium-image-zoom/dist/styles.css"

const ProjectTemplate = ({ data: { project, next } }) => {
  const featuredImage =
    project?.featuredImage != null
      ? {
          localFile: project?.featuredImage?.node?.localFile,
          alt: project?.featuredImage?.node?.alt || ``,
        }
      : null

  return (
    <Layout>
      <Seo title={project?.title} />

      <article
        className="project"
        itemScope
        itemType="http://schema.org/Article"
      >
        {featuredImage && (
          <Fade>
            <FeaturedImage>
              {project?.projectFields?.logo?.localFile?.publicURL && (
                <ProjectLogo>
                  {project?.projectFields?.logo?.mimeType ===
                    "image/svg+xml" && (
                    <img
                      src={project?.projectFields?.logo?.localFile?.publicURL}
                      alt={`The ${project?.title} project logo`}
                    />
                  )}
                  {project?.projectFields?.logo?.mimeType !==
                    "image/svg+xml" && (
                    <GatsbyImage
                      image={getImage(project?.projectFields?.logo?.localFile)}
                      alt={`The ${project?.title} project logo`}
                    />
                  )}
                </ProjectLogo>
              )}
              <GatsbyImage
                image={getImage(featuredImage?.localFile)}
                alt={featuredImage?.alt}
              />
            </FeaturedImage>
          </Fade>
        )}

        {project?.pageMeta?.pageTitle && (
          <PageTitle>{project?.title}</PageTitle>
        )}

        {project?.pageMeta?.pageScroller && (
          <Scroller showMobile={featuredImage != null ? true : false} />
        )}

        <ProjectWrapper>
          <ProjectMeta>
            <h1>
              <span>Project —</span>
              <span>{project?.title}</span>
            </h1>
            <h2>
              <span>Location —</span>
              <span>
                {!!project.projectFields?.location &&
                  project?.projectFields?.location}
              </span>
            </h2>
          </ProjectMeta>
          {!!project?.projectFields?.description && (
            <ProjectDescription>
              <Fade>{parse(project?.projectFields?.description)}</Fade>
            </ProjectDescription>
          )}
        </ProjectWrapper>

        <ProjectGallery>
          {project?.projectFields?.projectImages?.map((image, index) => {
            return (
              <div key={index} className={image?.imageWidth}>
                <Fade>
                  <Zoom>
                    <GatsbyImage
                      alt={image?.projectImage?.altText}
                      image={getImage(image?.projectImage?.localFile)}
                    />
                  </Zoom>
                </Fade>
              </div>
            )
          })}
        </ProjectGallery>
      </article>

      <ProjectNav>
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            <Link to="/#projects" rel="" className="back">
              Back to Projects
            </Link>
          </li>

          <li>
            {next && (
              <Link to={next.uri} rel="next" className="next">
                Next project: {parse(next?.title)}
              </Link>
            )}
          </li>
        </ul>
      </ProjectNav>
    </Layout>
  )
}

export default ProjectTemplate

const PageTitle = styled.h1`
  font-family: "degular-display", sans-serif;
  font-weight: 400;
  margin-top: 3rem;
  margin-bottom: 3rem;
  ${inner};
  letter-spacing: 0.5px !important;
`

const ProjectNav = styled.nav`
  ${inner};
  a {
    font-family: "degular-text", sans-serif;
    font-weight: bold;
    color: ${icg_blue};
    font-size: 1.125em;

    &.back {
      ${action_link}
    }

    &.next {
      ${action_link_reverse}
    }
  }
`

const FeaturedImage = styled.div`
  position: relative;
  ${featured_image}
`

const ProjectLogo = styled.div`
  position: absolute;
  z-index: 1;
  height: 35%;
  display: flex;
  justify-content: center;

  .gatsby-image-wrapper {
    height: 100% !important;
    width: 100% !important;
    max-width: 100% !important;
  }

  img {
    max-width: 100%;
    height: 100%;
    width: auto;
    margin: 0 auto;
    object-fit: contain !important;
  }
`

const ProjectMeta = styled.div`
  flex: 1;
  margin-right: 6rem;
  h1,
  h2 {
    line-height: 1.375rem;
    font-size: 1em;
    span {
      display: block;
      &:nth-of-type(1) {
        font-weight: bold;
        margin-bottom: 0.25rem;
      }
      font-weight: normal;
    }
  }
  @media (max-width: 47.999em) {
    width: 100%;
    flex: unset;
    margin-bottom: 3rem;
    margin-right: 0;
  }
`
const ProjectDescription = styled.section`
  @media (min-width: 48em) {
    padding-top: 6rem;
  }

  flex: 4;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: "degular-display", sans-serif;
    font-weight: 200;
    letter-spacing: 1px;
  }

  a {
    ${action_link};
  }
`
const ProjectWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  ${inner};
  margin-top: 3rem;
  margin-bottom: 3rem;
`

const ProjectGallery = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;

  div {
    width: 100%;
  }

  .gatsby-image-wrapper {
    max-height: 100%;
    width: 100%;
  }

  .half {
    width: 100%;
    @media (min-width: 48em) {
      width: calc(50% - 1rem);
    }

    margin-bottom: 1.5rem;
    .gatsby-image-wrapper {
      height: 0;
      ${image_aspect};
    }

    position: relative;
  }

  .full {
    width: 100%;
    margin-bottom: 1.5rem;
    .gatsby-image-wrapper {
      height: 0;
      ${image_aspect};
    }
    position: relative;
  }

  .react-reveal {
    img {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
`

export const pageQuery = graphql`
  query ProjectById(
    # these variables are passed in via createPage.pageContext in gatsby-node.js

    $id: String
    $previousProjectId: String
    $nextProjectId: String
  ) {
    # selecting the current post by id
    # sample comment
    project: wpProject(id: { eq: $id }) {
      id
      title

      seo {
        title
        opengraphDescription
        opengraphTitle
        opengraphType
        opengraphUrl
        readingTime
        twitterDescription
        twitterTitle
      }

      pageMeta {
        pageScroller
        pageTitle
      }

      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 2400
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
      }

      projectFields {
        description
        location
        logo {
          altText
          mimeType
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(
                width: 2400
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
        projectImages {
          imageWidth
          projectImage {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 2400
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
        }
      }
    }

    # this gets us the previous post by id (if it exists)
    previous: wpProject(id: { eq: $previousProjectId }) {
      uri
      title
    }

    # this gets us the next post by id (if it exists)
    next: wpProject(id: { eq: $nextProjectId }) {
      uri
      title
    }
  }
`
